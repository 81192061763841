import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import type { Language } from '@packages/config';
import config, { getFullLocale } from '@packages/config';
import { fetchRequiredStaticJson } from '@packages/modules/src/Header/api';
import { ErrorBoundary, SnackbarProvider } from '@packages/shared';
import { createEmotionCache } from '@packages/shared/src/utils/createEmotionCache/createEmotionCache';
import { execParallel } from '@packages/shared/src/utils/execParallel/execParallel';
import { isMonitoringCacheKey } from '@packages/shared/src/hooks/useIsMonitoring/useIsMonitoring';
import { sendWebVitals } from '@packages/shared/src/utils/sendWebVitals/sendWebVitals';
import { usePersistentScrid } from '@packages/shared/src/hooks/usePersistentScrid/usePersistentScrid';
import { cached } from '@packages/shared/src/utils/cached/cached';
import { LanguageProvider } from '@packages/shared/src/providers/LanguageProvider/LanguageProvider';
import { theme } from '@packages/themes';
import { TrackingProvider } from '@packages/tracking/src/components/TrackingProvider/TrackingProvider';
import type { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import App from 'next/app';
import { usePathname } from 'next/navigation';
import { parseCookies, setCookie } from 'nookies';
import { SWRConfig } from 'swr';
import { ConfigProvider } from '@packages/shared/src/providers/ConfigProvider/ConfigProvider';
import { CookieProvider } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { SessionProvider } from '@packages/shared/src/providers/SessionProvider/SessionProvider';
import { getLanguageFromUrl } from '@packages/shared/src/utils/getLanguageFromUrl/getLanguageFromUrl';
import { getNavigationDataEndpoint } from '@packages/modules/src/Header/Navigation/getNavigationDataEndpoint';
import type { Device } from '@packages/themes/src/default';
import { UrqlProvider } from '@packages/gql/src/urql';
import { localizedPathnameCacheKey } from '@packages/config/src/default';
import { PageviewProvider } from '@packages/tracking/src/components/PageviewProvider/PageviewProvider';
import type { UspData } from '@packages/cms-components/src/modules/Usp/types';
import Script from 'next/script';

import '@packages/themes/dist/default-theme.css';
import { getUspEndpoint } from '@packages/cms-components/src/utils/getUspEndpoint';
import { bucketFetcher } from '@packages/cms-components/src/utils/fetcher';
import { getUspCacheKey } from '@packages/cms-components/src/utils/getUspCacheKey';
import messages from '../../locales/compiled-messages';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface HeaderAppProps extends AppProps<any> {
  emotionCache?: EmotionCache;
  device: Device;
  isBot: boolean;
  isMonitoring: boolean;
  cookies: Record<string, string>;
  fallback: Record<string, any> | undefined;
}

// const PlaceholderSuspenseFallback = () => (
//   <div>If you see this on a live site, please kindly notify a developer</div>
// );

// const PageErrorPlaceholder = () => <div>The page just died 😢</div>;

const MyApp = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
  device,
  cookies,
  fallback,
  isBot,
}: HeaderAppProps) => {
  const pathname = usePathname();
  const language = getLanguageFromUrl(pathname ?? '');
  const {
    i18n: { defaultLocale },
    captcha: { recaptchaSessionToken },
  } = config;
  // if (router.pathname = "/warenkorb") { // We can figure out our route from here

  // }
  const locale = (language && getFullLocale(language as Language)) ?? defaultLocale;

  const { fallback: pageFallback, ...otherPageProps } = pageProps;
  const pageFallbackFinal = {
    ...pageFallback,
    // force reset of localizedPathname if not exist
    // used by LocaleSwitchSlot.tsx
    [localizedPathnameCacheKey]: pageFallback?.[localizedPathnameCacheKey] || null,
  };

  usePersistentScrid();

  return (
    <CacheProvider value={emotionCache}>
      {recaptchaSessionToken !== '' && (
        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${recaptchaSessionToken}&waf=session`}
          async
          defer
        />
      )}
      <CookieProvider cookies={cookies}>
        <ConfigProvider config={config}>
          <SessionProvider>
            <UrqlProvider pageProps={pageProps}>
              <LanguageProvider
                locale={locale}
                defaultLocale={config.i18n.defaultLocale}
                messages={messages[locale as keyof typeof messages]}
                onWarn={() => null}
              >
                <TrackingProvider
                  tagmanagerId={config.tracking.tagmanagerId}
                  domain={config.tracking.tagmanagerDomain}
                  src={config.tracking.tagmanagerSrc}
                  isBot={isBot}
                >
                  <PageviewProvider initialPageviewId={fallback?.pageviewId}>
                    <ThemeProvider theme={theme(device)}>
                      <SnackbarProvider>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <SWRConfig
                          value={{
                            fallback: { ...fallback, ...pageFallbackFinal },
                            dedupingInterval: 3000,
                          }}
                        >
                          {/* TODO get useful fallback from UI/UX */}
                          <ErrorBoundary fallback={<div />}>
                            <Component {...otherPageProps} />
                          </ErrorBoundary>
                        </SWRConfig>
                      </SnackbarProvider>
                    </ThemeProvider>
                  </PageviewProvider>
                </TrackingProvider>
              </LanguageProvider>
            </UrqlProvider>
          </SessionProvider>
        </ConfigProvider>
      </CookieProvider>
    </CacheProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  const {
    query: { lang },
  } = ctx;
  const defaultLanguage = config.i18n.defaultLocale.split('-')[0];

  let fallback: HeaderAppProps['fallback'];

  const detectedDevice: Device = ctx.req?.headers['x-ua-device'] as Device;
  const isBot: boolean = ctx.req?.headers['x-ua-bot'] !== undefined;
  const isMonitoring = ctx.req?.headers['x-ua-monitoring'] !== undefined;
  const cookies = parseCookies(ctx);

  if (ctx.req) {
    const {
      clientId,
      navigation: { loadSliced },
    } = config;
    const language = (typeof lang === 'string' && lang) || defaultLanguage || 'de';
    const localeForCacheKey = getFullLocale(language);

    const navEndpoint = getNavigationDataEndpoint(
      clientId,
      detectedDevice === 'desktop' ? 'web' : 'mob',
      localeForCacheKey,
      loadSliced,
    );

    const uspEndpoint = getUspEndpoint(language);

    const uspCacheKey = getUspCacheKey(
      detectedDevice === 'desktop' ? 'web' : 'mob',
      localeForCacheKey,
    );

    const result = await execParallel([
      cached(navEndpoint, () => fetchRequiredStaticJson(navEndpoint, { componentName: '_app' })),
      cached(uspCacheKey, () => bucketFetcher<UspData>(uspEndpoint)()),
    ]);
    let pageviewId = cookies.GlycerinPageViewId;
    const cookieFromBackend = cookies.GlycerinPageViewIdFromBackend as string | undefined;
    if (!cookieFromBackend) {
      pageviewId = crypto.randomUUID();
      // set cookie for next request to distinguish backend-api tracking call and nextjs header
      setCookie(ctx, 'GlycerinPageViewIdFromBackend', 'writtenFromHeaderApp');
      setCookie(ctx, 'GlycerinPageViewId', pageviewId);
    }

    fallback = {
      [isMonitoringCacheKey]: isMonitoring,
      [navEndpoint]: result[0],
      [uspCacheKey]: result[1],
      pageviewId,
    };
  }

  const appProps = await App.getInitialProps(appContext);

  return {
    ...appProps,
    fallback,
    device: detectedDevice,
    cookies,
    isBot,
  };
};

// Report web-vitals - see https://nextjs.org/docs/advanced-features/measuring-performance
export function reportWebVitals(metric: NextWebVitalsMetric) {
  sendWebVitals(metric);
}

// eslint-disable-next-line import/no-default-export
export default MyApp;
